<template>
  <v-dialog v-model="chatDialog" max-width="1600px">
    <v-toolbar flat>
      <v-toolbar-title>Chat Banned Detail</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="timeleftSelect"
        :items="timelefts"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="changeTime"
        hide-details
        label="Time Left"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-text-field
        v-model="timeleftManuel"
        label="Time Left(Seconds)"
        single-line
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="gold"
        label="Gold"
        single-line
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn
        color=" green"
        dark
        @click="chatBanned(userDetail.userId)"
        class="mb-2"
        >Chat Ban</v-btn
      >
    </v-toolbar>
    <v-toolbar flat>
      <v-toolbar-title>User Data</v-toolbar-title>
    </v-toolbar>
    <json-viewer
      name="df"
      :value="$store.state.users.user"
      :expand-depth="1"
      theme="my-awesome-json-theme"
      sort
    ></json-viewer>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  name: "ChatBannedModalCard",
  watch: {
    async clicked() {
      this.chatDetail(this.userId);
    },
  },
  props: {
    clicked: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      chatDialog: false,
      userDetail: {},
      user: null,
      timeleftSelect: null,
      timeleftManuel: null,
      gold: 0,
      timelefts: [
        { value: "10 Minutes", key: 60 * 10 },
        { value: "1 Hours", key: 60 * 60 },
        { value: "4 Hours", key: 60 * 60 * 4 },
        { value: "12 Hours", key: 60 * 60 * 12 },
        { value: "24 Hours", key: 60 * 60 * 24 },
        { value: "3 Days", key: 60 * 60 * 24 * 3 },
        { value: "7 Days", key: 60 * 60 * 24 * 7 },
        { value: "14 Days", key: 60 * 60 * 24 * 14 },
        { value: "1 Months", key: 60 * 60 * 24 * 30 },
        { value: "3 Months", key: 60 * 60 * 24 * 30 * 3 },
      ],
    };
  },
  methods: {
    changeTime() {
      this.timeleftManuel = null;
    },
    async chatDetail(userId) {
      this.timeleftManuel = null;
      await this.$store.dispatch("users/getById", userId);
      this.user = this.$store.state.users.user;
      this.userDetail = {
        userId: userId,
        reports: this.$store.state.userReports.report,
      };
      this.chatDialog = true;
    },

    async chatBanned(userId) {
      await this.$store.dispatch("userReports/chatBanned", {
        userId: userId,
        timeLeft: parseInt(this.timeleftManuel) || this.timeleftSelect.key,
        gold: this.gold,
      });
      this.$store.dispatch("setAlert");

      this.chatDialog = false;
    },
  },
};
</script>
